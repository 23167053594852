
import React, { Component } from 'react'
import SVG from 'react-inlinesvg';
import Down from "../../assets/images/down.svg";
import smoothscroll from 'smoothscroll-polyfill';



export default class Hero extends Component {


  constructor(props) {
    super(props);

    this.onScrollHandler = this.onScrollHandler.bind(this);
    this.blockRef = React.createRef();
    this.iconRef = React.createRef();
  }

  componentDidMount() {
    smoothscroll.polyfill();
    window.addEventListener("scroll", this.onScrollHandler);

  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollHandler);

  }



  render() {
    const { data } = this.props;

    return (
      <div className="container-fluid width835 hero" ref={this.blockRef}>
        <h1 dangerouslySetInnerHTML={{ __html: data.title }}></h1>
        {
          data.text && <div dangerouslySetInnerHTML={{ __html: data.text.childMarkdownRemark.html }}></div>
        }

        <button className="applied-btn" onClick={() => this.scrollToNextBlock()}>
          Learn More
            </button>
        <div ref={this.iconRef} className="hero__down-icon" onClick={() => this.scrollToNextBlock()}>
          <SVG src={Down} />
        </div>


      </div>
    )
  }

  scrollToNextBlock() {
    const scrollHeight = this.blockRef.current.offsetHeight;
    window.scroll({
      top: scrollHeight,
      behavior: "smooth"
    });
  }



  onScrollHandler(e) {
    const isInvisible = window.scrollY > this.blockRef.current.offsetHeight / 2;
    this.iconRef.current.classList.toggle("invisible", isInvisible);
  }
}

