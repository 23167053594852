import React from "react";

import Hero from "./agencies/Hero";
import Title from "./agencies/Title";
import Feature from "./agencies/Feature";
// import Promo from "./agencies/Promo";
// import Showcase from "./agencies/Showcase";
import Partners from "./agencies/Partners";
import Form from "./agencies/Form";
import Footer from "./agencies/Footer";

const mappedBlocks = {
  ContentfulHeroBlock: Hero,
  ContentfulTitleBlock: Title,
  ContentfulFeatureBlock: Feature,
  ContentfulFooterBlock: Footer,
  ContentfulFormBlock: Form,
  // ContentfulShowcaseBlock: Showcase,
  ContentfulPartnersBlock: Partners,
};

const BlockMapper = props => {
  const Block = mappedBlocks[props.type];
  return <Block  {...props} />
};
export default BlockMapper;
