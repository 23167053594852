import React from 'react'
import PropTypes from 'prop-types'

import SVG from 'react-inlinesvg';

const Feature = ({ data }) => {
  const containerClass = data.onRight ? "feature--right" : "";
  const coverClass = data.onRight ? "order-0 order-lg-1" : ""
  return (
    <div className={`container feature ${containerClass}`}>
      <div className="row align-items-center no-gutters">
        <div className={`col-lg-6 feature__cover ${coverClass}`}>
          <SVG src={data.icon.file.url} className="feature__icon" style={{ maxWidth: data.iconMaxWidth }} />
          <h3 className="feature__title">
            {data.title}
          </h3>
          <div className="feature__subtitle" dangerouslySetInnerHTML={{ __html: data.subtitle.childMarkdownRemark.html }}></div>
        </div>
        <div className="col-lg-6">
          <ul className="feature__description tiled-list">
            {
              data.list.map((item, index) => (
                <li className="tiled-list__item" key={`feature-item-${index}`}>
                  {
                    item.icon && <SVG src={item.icon.file.url} className="tiled-list__icon" />
                  }
                  <h5>
                    {item.title}
                  </h5>
                  <div dangerouslySetInnerHTML={{ __html: item.description.childMarkdownRemark.html }}></div>
                </li>
              ))
            }


          </ul>
        </div>
      </div>
    </div>
  )
}


Feature.propTypes = {
  onRight: PropTypes.bool
}

Feature.defaultProps = {
  onRight: false
}


export default Feature