import React, { Component } from 'react'

export default class Promo extends Component {
  componentDidMount(){
    document.documentElement.style.setProperty('--partners-background', this.props.data.backgroundColor);
    document.documentElement.style.setProperty('--partners-background-dark', this.props.data.darkBackgroundColor);
    document.documentElement.style.setProperty('--partners-color', this.props.data.color);
    document.documentElement.style.setProperty('--partners-color-dark', this.props.data.darkColor);
  }
  
  render(){
    const { data } = this.props;
    return (
    <div className="partners">
      <div className="container">
        
        <div className="row align-items-center justify-content-around">
          <div className="col-12">
          <h2>{data.title}</h2>
          </div>
          {
            data.partners.map((item, index) =>
              <div className="col-6 col-md-4 col-lg-3 mb-5 mt-5" key={`partners-block-${index}`}>
                <img src={item.file.url} alt={item.file.fileName} className="partners__logo"/>
              </div>
            )
          }
        </div>
      </div>
    </div>



  )
  }
  
}