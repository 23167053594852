import React from "react"

import SVG from 'react-inlinesvg';


const Footer = ({ data }) => {
  return (
    <footer className="footer footer--agencies">
      <div className="container-fluid width1280">
        <div className="row justify-content-center justify-content-lg-start">
          <div className="col-12">
            <h3 className="footer__heading">{data.title}</h3>
          </div>
          <div className="col-lg-4">
            <SVG src={data.logo.file.url} />
          </div>

          {
            data.blocks.map((item, index) => (
              <div className="col-lg-2 col-6 footer-block" key={`footer-block-${index}`} >
                <h5 className="footer-block__title">{item.title}</h5>
                <div dangerouslySetInnerHTML={{ __html: item.description.childMarkdownRemark.html }}></div>
              </div>
            ))
          }

        </div>
        <div className="row footer__bottom">
          <div className="col">
            © {new Date().getFullYear()}, Applied Labs LLC.
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer
