import React from 'react';
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import BlockMapper from '../components/BlockMapper';



const AgencyLanding = ({ data, location }) => {
  const layoutData = {
    data: data
  }

  const blocks = data.landing.blocks;
  return (
    <Layout data={layoutData} location={location} className={"agency-page"}>
      <SEO title={data.landing.title} description={data.landing.seoDescription.seoDescription} />
      {blocks ? blocks.filter((item) => (item.id != null)).map((block, index) => (
          <BlockMapper type={block['__typename']} data={block} key={index} />
      )) : null}
    </Layout>
  )
}
export default AgencyLanding


export const query = graphql`
  query AgencyLandingQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  # us: allContentfulAgencies(sort: {order: DESC, fields: createdAt}, filter: {node_locale: {eq: "en-US"}}) {
  #   nodes {
  #     title
  #     slug
  #   }
  # }
  # ru: allContentfulAgencies(sort: {order: DESC, fields: createdAt}, filter: {node_locale: {eq: "ru"}}) {
  #   nodes {
  #     title
  #     slug
  #   }
  # }
  landing: contentfulAgenciesLandingPage(node_locale: {eq: "en-US"}) {
    title
    seoDescription {
      seoDescription
    }
    blocks {
      __typename
      ... on ContentfulHeroBlock {
        id
        title
        text {
          childMarkdownRemark {
            html
          }
        }
      }
      # ... on ContentfulPromoBlock {
      #   id
      #   title
      #   backgroundColor
      #   itemBackground
      #   itemHoverBackground
      #   darkBackgroundColor
      #   darkItemBackground
      #   darkItemHoverBackground
      #   items {
      #     id
      #     title
      #     link
      #     description {
      #       id
      #       childMarkdownRemark {
      #         html
      #       }
      #     }
      #   }
      # }
      ... on ContentfulTitleBlock {
        id
        title
        subtitle {
          childMarkdownRemark {
            html
          }
        }
      }
      ... on ContentfulFeatureBlock {
        id
        title
        subtitle {
          id
          childMarkdownRemark {
            html
          }
        }
        onRight
        iconMaxWidth
        icon {
          file {
            url
          }
        }
        list {
          id
          title
          icon {
            file {
              url
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      # ... on ContentfulShowcaseBlock {
      #   id
      #   items {
      #     title
      #     link
      #     description {
      #       childMarkdownRemark {
      #         html
      #       }
      #     }
          
      #   }
      # }
      ... on ContentfulPartnersBlock {
        id
        color
        backgroundColor
        darkColor
        darkBackgroundColor
        title
        partners {
          file {
            url
            fileName
          }
        }
      }
      ... on ContentfulFooterBlock {
        id
        title
        logo {
          file {
            url
          }
          title
        }
        blocks {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          id
        }
      }
      ... on ContentfulFormBlock {
        id 
        title 
      }
    }
  }

}
`;