import React from 'react'

const Title = ({data}) => (
  <div className="title-block">
    <h2 dangerouslySetInnerHTML={ {__html: data.title}}></h2>
    {
      data.subtitle && <div dangerouslySetInnerHTML={ {__html: data.subtitle.childMarkdownRemark.html}}></div>
    }
  </div>

)


export default Title;